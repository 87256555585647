import React, { useState ,useEffect, useContext } from 'react'
import '../../css/fav.scss'
import Grey_nav from '../grey_Nav'
import axios from 'axios';
import { UserContext } from '../../context/userContext';
import Product from './product';
import '../../css/responsive/fav_res.scss'
import { Paginator } from 'primereact/paginator';
export default function Fav() {
    const [isWindowClicked, setIsWindowClicked] = useState(false);
    const [selectedValue, setSelectedValue] = useState('asc');

    const handleClickWindow = () => {
        setIsWindowClicked(true);
    };

    const handleClickBurger = () => {
        setIsWindowClicked(false);
    };

    const [favProducts, setFavProducts] = useState([])
    let { baseURL,token,updateFavLength, favLength } = useContext(UserContext);
// ---------------------- delete product from Favorites ---------------------
  const removeFromFav = (id,v_id) => {
    
    axios.delete(`${baseURL}/favorites`, {
        data: { product_id: id, variation_value_id: v_id },
        headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(res => {
        
        
        setFavProducts((prevFavProducts) => {
          const updatedFavProducts = prevFavProducts.filter(
            product => !(product.product_id === id && product.variation_attributes.variation_value_id === v_id)
          );
          // window.location.reload(); 
          // Update the pagination total records
          // setPagination((prevPagination) => {
          //   const newTotalRecords = updatedFavProducts.length;
          //   let newFirst = prevPagination.first;

          //   // Adjust the first index if necessary
          //   if (newFirst >= newTotalRecords && newFirst > 0) {
          //     newFirst = Math.max(0, newFirst - prevPagination.rows);
          //   }

          //   

          //   return {
          //     ...prevPagination,
          //     totalRecords: newTotalRecords,
          //     first: newFirst
          //   };
          // });

          return updatedFavProducts;
        });


        // setFavProducts((prevFavProducts) => prevFavProducts.filter(product => product.product_id !== id && product.variation_attributes.variation_value_id !== v_id));
        updateFavLength(false);

       




       



    })
      .catch();
  };
    // -----------------------------------------------------------------------------------------------------
    const handleChange = (event) => {
      const {value } = event.target;
      setSelectedValue(value);
      fetchFavProducts();
    //   handleFilterObj(name, value);
  };
    //------------------------ for pagination -------------------------------------------
    const [pagination, setPagination] = useState({first:0,from:0,to:0,totalRecords:0,rows:12})
    useEffect(() => {
        fetchFavProducts(pagination.first / pagination.rows + 1);
        // 
      }, [pagination.first, pagination.rows, baseURL, token]);
  
    const onPageChange = (e) => {
        setPagination({
            ...pagination,
            first:e.first,
            rows:e.rows
        })
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    
    // -----------------------------------------------------------------------------------------------------
    const fetchFavProducts=(page)=>{
      
        const queryParams = new URLSearchParams({page: page , sort: selectedValue }).toString();
        setFavProducts('');
        axios.get(`${baseURL}/favorites?${queryParams}`, {headers: { 'Authorization': `Bearer ${token}` }})
        .then((response) => {
          const data = response.data.data;
          setFavProducts(response.data.data.data);
            setPagination({
              ...pagination,
              totalRecords: data.total,
              from: data.from,
              to: data.to,
              rows: data.per_page
          });
        })
        .catch((error) => {  })
    }


    
    // -----------------------------------------------------------------------------------------------------


    useEffect(() => {
    
      const prevButton = document.querySelector('.p-paginator-prev');
      const nextButton = document.querySelector('.p-paginator-next');
      
      if (prevButton) {
        prevButton.addEventListener('click', handleScrollToTop);
    }
  
      if (nextButton) {
          nextButton.addEventListener('click', handleScrollToTop);
      }
  
    
  }, [pagination]);
  const handleScrollToTop = () => {
    
    setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0); 
};



    return (
        <div className='address'>
            <Grey_nav />
            <div className="address-wrapper" id='fav_wrapper'>
                {/* <img src="./assests/fav-flower.svg" alt="" className='fav-flower-img' /> */}

                <div className="address-orders">My Shortlist
                {favProducts && <div className="fav-abs-no">{favLength}</div>}
                </div>
                {/* <div className="address-you">Lorem ipsum</div> */}
                {favProducts && <div className="fav-items-sort" id='fav-items-sort'>
                    <div className="fav-4-items">{favLength} items</div>
                    <div className="fav-sort-default" id='fav-sort-default'>
                        <div className="fav-sort">Sort by</div>
                        <select name="sort" className="sort-select" value={selectedValue} onChange={handleChange}>
                            <option value="asc">Asc</option>
                            <option value="desc">Desc</option>
                        </select>
                        {/* <div className="fav-default">Default sorting</div> */}
                        <img src="./assests/square.svg" alt="" className='fav-window' id='fav-window'  onClick={handleClickBurger} />
                        <img src="./assests/lines.svg" alt="" className='fav-window' onClick={handleClickWindow} />

                    </div>
                </div>}
                <div className={"fav-details-images row " + (isWindowClicked && 'fav-details-col')} id='fav-details-images'>

                    {
                        favProducts.length>0 &&  favProducts.map(product=>{
                            return <Product key={product.id} product={product} isWindowClicked={isWindowClicked} removeFromFav={removeFromFav}/>
                        })
                    }
                </div>
            </div>
            {favProducts && <div className="my-1">
                            <Paginator first={pagination.first} rows={pagination.rows} totalRecords={pagination.totalRecords} rowsPerPageOptions={[12, 24, 36]} onPageChange={onPageChange} />
                        </div>}
        </div>
    )
}
